/* ============================ */
/*            Header            */
/* ============================ */

.header-area {
  padding: 0 0 0 30px;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  .col-md-6 {
    @media (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .mobile-logo {
    left: 30px;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    width: 25px;
    z-index: 1;
  }
  .nav-btn {
    margin-right: 30px;
    margin-top: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 30px;
    padding-top: 18px;
    @media (max-width: 991px) {
      margin-right: 0;
      margin-top: 0;
      height: auto;
      width: auto;
    }
    span {
      width: 22px;
      height: 1px;
      background: theme-color-main(dark);
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      text-align: center;
      display: block;
      margin: 0 auto 6px;
      &:nth-child(3) {
        margin-bottom: 0;
      }

      &:nth-child(3),
      &:nth-child(1) {
        width: 15px;
      }
    }
  }
  .search-box {
    form {
      position: relative;
      input {
        width: 270px;
        padding-right: 11px;
        border-radius: 33px;
        border: none;
        height: 40px;
        padding-left: 40px;
        letter-spacing: 0;
        font-size: 14px;
        font-weight: 500;
      }
      input::-webkit-input-placeholder {
        color: #b1a7a7;
      }
      input::-moz-placeholder {
        color: #b1a7a7;
      }
      input:-ms-input-placeholder {
        color: #b1a7a7;
      }
      i {
        position: absolute;
        left: 6px;
        top: 10px;
        font-size: 22px;
        color: #b1a7a7;
      }
    }
  }
}

.sidebar_collapsed {
  .nav-btn span:nth-child(2) {
    opacity: 0;
  }
  .nav-btn span:first-child {
    -webkit-transform: rotate(90deg) translate(5px, 5px);
    transform: rotate(90deg) translate(5px, 5px);
  }
  .nav-btn span:last-child {
    -webkit-transform: rotate(90deg) translate(-9px, -3px);
    transform: rotate(90deg) translate(-9px, -3px);
  }
}

/* notification-area */

.notification-area {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  z-index: 2;
  li {
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .bell-notify-box.notify-box {
    @media only screen and (max-width: 480px) {
      right: -120px !important;
      width: 280px;
    }
  }
  .nt-enveloper-box.notify-box {
    @media only screen and (max-width: 480px) {
      right: -78px !important;
      width: 280px;
    }
  }
  .user-dropdown {
    .btn {
      padding: 0;
      margin: 0 auto;
      border-radius: 0;
      font-size: 13px;
      color: #b1a7a7;
      box-shadow: none;
      background: transparent;
      border: none;
    }
    .btn.dropdown-toggle:after {
      position: absolute;
      top: 26px;
      right: 70px;
      font-size: 15px;
    }
    .btn span,
    .btn img {
      display: inline-block;
      vertical-align: middle;
    }
    .btn span {
      padding: 0 15px;
      i {
        font-size: 10px;
        font-weight: bold;
        vertical-align: middle;
        margin-left: 2px;
      }
    }
    .dropdown-menu.show {
      top: 68px !important;
    }
    .dropdown-menu {
      .divider {
        margin: 10px 0;
        display: block;
        border: 0.5px solid #f5f4f4;
      }
      a {
        font-size: 14px;
        color: #8a8a8a;
        letter-spacing: 0;
        font-weight: 500;
        padding: 10px 70px 10px 25px;
        i {
          margin-right: 10px;
          vertical-align: baseline;
        }
      }
    }
  }
}

.notification-area li > i {
  font-size: 18px;
  color: #bdbcbc;
  vertical-align: middle;
  -webkit-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
}

.notification-area li:hover > i,
.dropdown.show > i {
  color: theme-color-main(primary);
}

.notification-area li i > span {
  position: absolute;
  right: -5px;
  top: -7px;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background: theme-color-main(primary);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  padding-left: 2px;
}

.notify-box {
  width: 350px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  .notify-title {
    background: theme-color-main(primary);
    display: block;
    padding: 18px 30px;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0;
    overflow: hidden;
    a {
      float: right;
      display: inline-block;
      color: theme-color-main(primary);
      font-size: 13px;
      background: #fff;
      padding: 2px 8px;
      border-radius: 4px;
      @media only screen and (max-width: 480px) {
        float: none;
        margin-top: 6px;
      }
    }
  }
}

.nofity-list {
  a {
    padding: 20px 30px;
    display: block;
    border-bottom: 1px solid #f5f2f2;
    @media only screen and (max-width: 480px) {
      padding: 20px 15px;
    }
    &:hover {
      background: theme-color-main(light);
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  .notify-thumb {
    float: left;
    margin-right: 20px;
    @media only screen and (max-width: 480px) {
      margin-right: 15px;
    }
    i {
      height: 50px;
      width: 50px;
      line-height: 50px;
      display: block;
      border-radius: 50%;
      text-align: center;
      font-size: 23px;
      border: 1px solid;
      @media only screen and (max-width: 480px) {
        font-size: 18px;
        height: 40px;
        line-height: 40px;
        width: 40px;
      }
    }
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-top: 4px;
      @media only screen and (max-width: 480px) {
        width: 50px;
        height: 50px;
      }
    }
  }
  .notify-text {
    overflow: hidden;
    h3 {
      color: #666;
      font-size: 15px;
      margin-bottom: 0;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      color: #4e4e4e;
      line-height: 22px;
      margin-bottom: 4px;
    }
    span {
      letter-spacing: 0;
      color: #6d6c6c;
      font-size: 11px;
      font-weight: 300;
    }
    span.msg {
      display: block;
      font-size: 12px;
      color: #4e4e4e;
      line-height: 22px;
      margin-bottom: 0;
      margin-top: 0;
      font-weight: 400;
    }
  }
}

/* notify envelope */

.expanded {
  .notification-area {
    li#full-view-exit {
      display: inline-block;
    }
  }
}

.notification-area {
  li#full-view-exit {
    display: none;
  }
}

.expanded .notification-area li#full-view {
  display: none;
}

.notification-area li#full-view {
  display: inline-block;
}

.nt-enveloper-box .notify-text p {
  margin-bottom: 0;
}

.notification-area .dropdown-toggle,
.user-name.dropdown-toggle {
  position: relative;
}

.notification-area .dropdown-toggle:after,
.user-name.dropdown-toggle:after {
  display: none;
}

.notification-area .dropdown-menu,
.user-profile .dropdown-menu {
  background: #fff;
  border: none;
  -webkit-transform: none !important;
  transform: none !important;
  top: 130% !important;
  right: -20px !important;
  left: auto !important;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  display: block !important;
  visibility: hidden;
  opacity: 0;
  cursor: initial;
}

.notification-area .dropdown-menu.show {
  top: 48px !important;
}

.notification-area .dropdown-menu.show,
.user-profile .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  box-shadow: 0 0 45px 0 rgba(131, 23, 254, 0.06);
}

/* ============================ */
/*          End Header          */
/* ============================ */
