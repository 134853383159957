/*-- Chart --*/
.c3 svg {

  -webkit-tap-highlight-color:#71748d; fill: #71748d; }

.c3 path, .c3 line {
  fill: none;
  stroke: #71748d; }

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; 
  fill: #71748d; }

 

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges; }

.c3-chart-arc path {
  stroke: #fff; }

.c3-chart-arc rect {
  stroke: white;
  stroke-width: 10; }

.c3-chart-arc text {
  fill: #fff; }

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa; }

.c3-grid text {
  fill: #6c757d; }

.c3-xgrid, .c3-ygrid {
  stroke-dasharray: 3 3; }

/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #71748d;
  font-size:14px; }

/*-- Line --*/
.c3-line {
  stroke-width: 3px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width:2px;
  stroke: white; }

.c3-selected-circle {
  fill: white;
  stroke-width: 2px; }

/*-- Bar --*/
.c3-bar {
  stroke-width: 0; }

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75; }

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1; }

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
  stroke-width: 2px; }

.c3-target.c3-defocused {
  opacity: 0.3 !important; }

/*-- Region --*/
.c3-region {
  fill: #71748d;
  fill-opacity: .1; }

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: .1; }

/*-- Select - Drag --*/

.c3-legend-item-hidden {
  opacity: 0.15; }

.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1; }

/*-- Title --*/
.c3-title {
  font-size: 14px;font-family: 'Circular Std Book'; }

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10; }

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  -webkit-box-shadow: 7px 7px 12px -9px #acacc6;
  -moz-box-shadow: 7px 7px 12px -9px #acacc6;
  box-shadow: 7px 7px 12px -9px #acacc6;
  opacity: 1; }

.c3-tooltip tr {
  border: 1px solid #e6e6f2; }

.c3-tooltip th {
  background-color: #fff;
  font-size: 14px;
  padding: 5px 8px;
  text-align: left;
  color: #3d405c; }

.c3-tooltip td {
  font-size: 14px;
   padding: 5px 8px;
  background-color: #fff;
  border-left: 1px dotted #999; }

.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px; }

.c3-tooltip td.value {
  text-align: right; color: #5969ff; }

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.08; }

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em; }

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: #FFF; }

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #6c757d;
  font-size: 16px; }

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777; }

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777; }

.c3-chart-arc .c3-gauge-value {
  fill: #6c757d;
  /*  font-size: 28px !important;*/ }

.c3-chart-arc.c3-target g path {
  opacity: 1; }

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1; }




